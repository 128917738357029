@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Enriqueta:wght@400;500;600;700&display=swap");

body, html {
  height: 100%;
  width: 100vw;
  margin: 15px 0 0 0;
  padding: 0;
  font-family: 'Poppins', Arial, sans-serif; 
  box-sizing: border-box;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  flex-shrink: 0;
}

.main-content {
  flex-grow: 1;
  padding: 0.5px;
  padding-top: 50px; 
}

footer {
  flex-shrink: 0;
  background-color: #4B143D;
  color: white;
  padding: 40px 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  gap: 20px;
  background-color: #4B143D;
  color: white;
  font-size: 1em;
}

.footer-left, .footer-center, .footer-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.footer-left a, .footer-center a, .footer-right a {
  color: white;
  text-decoration: none;
  font-size: 1em;
}

.footer-left a:hover, .footer-center a:hover, .footer-right a:hover {
  color: #D19DFF;
}

.footer-left p {
  margin: 10px 0;
}

.footer-social {
  display: flex;
  gap: 15px;
}

.social-icon {
  width: 24px;
  height: 24px;
  filter: invert(100%);
}

.mivision-link {
  color: #FFD700;
  font-weight: bold;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.mivision-link:hover {
  color: #FFFFFF;
  text-decoration: none;
}

/* Navbar styles */
.navbar {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  color: #451D47;
  padding: 10px 20px;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  max-height: 80px;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

.navbar.scrolled {
  background-color: rgba(236, 217, 250, 0.819);
  backdrop-filter: blur(5px);
}

.navbar-logo {
  margin-right: auto; 
}

.navbar-logo img {
  height: 60px; 
  border-radius: 50%;
  object-fit: cover;
}

.navbar-links {
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-grow: 1; 
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0 15px;
  font-size: 1.25em;
}

.navbar-links li a {
  color: #57155b;
  text-decoration: none;
  font-weight: 400;
}

.social-icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.social-icons a {
  color: #451D47;
  text-decoration: none;
}

.social-icons a:hover {
  color: #D19DFF;
}

.burger-menu {
  display: none;
  cursor: pointer;
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.burger-menu div {
  width: 100%;
  height: 3px;
  background-color: #451D47;
  transition: all 0.3s ease;
}

.burger-menu.open .bar1 {
  transform: rotate(-45deg) translate(-6px, 6px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  transform: rotate(45deg) translate(-6px, -6px);
}

/* Media Queries */
@media (max-width: 768px) {
  .navbar {
    flex-wrap: wrap;
  }

  .burger-menu {
    display: flex;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #ecd9fae1; 
    border-top: 1px solid #451D47;
    border-radius: 0 0 15px 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    box-sizing: border-box;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }

  .navbar-links.open {
    max-height: 400px;
  }

  .navbar-links li {
    margin: 10px 0;
  }

  .social-icons {
    justify-content: center;
    margin-top: 10px;
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-left, .footer-center, .footer-right {
    align-items: center;
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) {
  .burger-menu {
    display: none; 
  }

  .social-icons {
    position: absolute;
    right: 20px;
  }
}
