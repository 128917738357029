.histoire-container {
  text-align: center;
  font-family: 'General Sans', sans-serif; /* Utilisation de la nouvelle police */
  background:  #dcd0e859;
}

.histoire-header {
  background-color: #f2e2fc;
  height: 40vh;
  color: #53195D;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.histoire-header h1 {
  font-size: 4em;
  font-family: 'Enriqueta', serif;
  color: #651249;
  margin: 0;
}

.histoire-section {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
  margin-left: 25px;
  margin-right: 25px;
  border-bottom: 2.5px solid #53195D;
}

.histoire-content, .citation-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  width: 80%;
  max-width: 1200px;
}

.citation-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: justify;
}
.citation-text span {
  font-weight: bolder;
  font-style: italic;
  font-size: 1.4em;
  font-family: "hind", sans-serif;
  color: #53195D;
  
}
.citation-image {
  max-width: 300px;
  height: 400px;
  border-radius: 5px;
  object-fit: fill;
}

.histoire-image {
  width: 40%;
  max-width: 400px;
  height: auto;
  border-radius: 5px;
}
.histoire-image-naissance {
  width: 60%;
  max-height: 600px;
  border-radius: 5px;
  object-fit: cover;
}

.histoire-text {
  width: 55%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: justify;
}
p {
  font-size: 1.2em;
  font-weight: lighter;
  font-family: 'hind', sans-serif;
}

.histoire-section h2 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 2.7em;
  font-family: 'Enriqueta', serif;
  color: #651249;
}

strong {
  color: #53534f;
}

.histoire-text p {
  margin-bottom: 20px;
  line-height: 1.6;
}

/* Media Queries pour la Responsivité */
@media (max-width: 768px) {
  .histoire-header h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 2.7em;
  }

  .histoire-content, .citation-content {
    flex-direction: column;
    width: 90%;
  }

  .histoire-image, .histoire-image-naissance {
    width: 100%;
    max-width: none;
    margin-bottom: 20px;
  }

  .histoire-text {
    width: 100%;
    padding: 0;
  }

  .histoire-text h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
}
