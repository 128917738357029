.home-header-content h1 {
  font-family: 'Shrikhand';
  font-weight: 700; 
  font-size: 7em;
  letter-spacing: 3px;
  color:  #dcd0e8;
}

.home-header-content h2 {
  padding-top: 10px;
  padding-bottom: 10px;
  color:  #dcd0e8;
  font-family: 'enriqueta', sans-serif; 
  font-size: 4em;
  font-weight:400; /* Appliquer la police aux titres */
  }
 

.home-container {
text-align: center;
display: flex;
flex-direction: column;
min-height: calc(100vh - 60px); /* Ajuste cette valeur en fonction de la hauteur de votre header et footer */
box-sizing: border-box;
font-family: 'General Sans', sans-serif; /* Utilisation de la nouvelle police */
}

.home-header {
position: relative;
color: white; /* Couleur du texte pour le contraste */
margin-bottom: 20px;
text-align: center;
flex-shrink: 0;
width: 100vw; 
left: 50%;
right: 50%;
margin-left: -50vw;
margin-right: -50vw;
}

.home-header::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.3); /* Overlay noir à 30% d'opacité pour mettre en valeur le texte blanc */
z-index: 1; /* Positionner l'overlay au-dessus de l'image mais en dessous du texte */
}

.home-header-background {
width: 100%;
height: auto;
display: block;
}

.home-header-content {
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
position: absolute;
height: 100%;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 2; /* Pour s'assurer que le texte est au-dessus de l'image */
}

.home-header h1, .home-header h2 {
margin: 0;
}

.call-to-story {
width: 300px;
margin-top: 20px;
padding: 10px 20px;
border: 2px solid #dcd0e8;
color:  #dcd0e8;
text-decoration: none;
font-weight: 600;
border-radius: 5px;
transition: background-color 0.3s ease;
font-size: 1.2em;
}

.call-to-story:hover {
background-color: #53195D;
}
section h3 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 2.3em;
  font-family: 'Enriqueta', serif;
  color: #651249;
}
.home-intro {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
height: 60vh;
font-size: 1.2em;
color: #333;
flex-grow: 1; /* Permet au contenu de croître pour remplir l'espace */
gap: 20px;
padding: 20px;
max-width: 80%; /* Centrer la section */
margin: 0 auto; /* Centrer la section */
}

.home-intro img {
width: 300px;
height: 300px;
border-radius: 50%;
object-fit: cover;
}

.citation-abdel {
width: 60%;
font-size: 1.1em;
line-height: 1.6;
text-align: justify;
margin: 0; /* Suppression des marges */
}
.citation-abdel span {
font-weight:bolder;
font-size: 1.15em;
font-weight: bolder;
font-family: "hind", sans-serif;
font-style: italic;
color: #53195D;
}

.citation-abdel br {
display: block;
margin-top: 10px; /* Ajout d'espace au-dessus du saut de ligne */
}

.experience-expertise {
background:  #dcd0e859;
height: 70vh;
display: flex;
flex-direction: column;
justify-content: center;
}

.experience-content {
display: flex;
align-items: center;
justify-content: center;
gap: 20px;
max-width: 80%;
margin: 0 auto;
}

.experience-content p {
font-size: 1.25em;
line-height: 1.6;
flex: 1;
text-align: justify;
}

.experience-content .expert-image {
width: 300px;
height: 300px;
border-radius: 50%;
object-fit: cover;
}

.priority-text {
position: relative;
display: inline-block;
font-size: 1.3em;
}

.priority-text::after {
content: '';
display: block;
width: 130px;
height: 10px;
background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="10" viewBox="0 0 100 10" fill="none"><path d="M2.86636 4.47802C14.5957 5.17828 26.4893 3.96808 38.2127 3.5956C58.0632 2.96491 77.9581 2.58856 97.8185 2.82784" stroke="purple" stroke-width="5" stroke-linecap="round"/></svg>') center/contain no-repeat;
position: absolute;
bottom: -5px;
left: 0;
}

.salon-pour-tous {
padding: 0px 20px;
margin-bottom: 20px;
background-color: white;
height: 90vh;
display: flex;
flex-direction: column;
justify-content: center;
}

.salon-content {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;
max-width: 80%;
margin: 0 auto;
}

.salon-content p {
display: flex;
flex-direction: row;
text-align: center;
font-size: 1.25em;
line-height: 1.6;
flex: 1;

}

.salon-content .salon-image {
width: 300px;
height: 300px;
object-fit: cover;
border-radius: 50%;
}

.salon-pour-tous p {
font-size: 1.25em;
line-height: 1.6;
text-align: justify;
max-width: 80%; /* Centrer le texte */
margin: 0 auto;
height: 100%;
}
.galerie-photo {
max-width: 100%;
background:  #dcd0e859;
height: 80vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 60px;
padding-top: 30px;
padding-bottom: 30px;
}

.carousel .slide {
background: none;
}

.carousel .slide img {
display: block;
margin: 0 auto;
max-height: 380px;
object-fit: contain;
}




.instagram-link {
color: white;
background-color: #651249;
width: 200px;
margin-top: 20px;
margin-bottom: 30px;
padding: 10px 20px;
text-decoration: none;
font-weight: 600;
border-radius: 5px;
}

.elfsight-container {
  max-width: 100%;
  overflow: hidden;
  padding: 0 15px;
  box-sizing: border-box;
}


/* Media Queries pour la Responsivité */
@media (max-width: 768px) {

  .home-header-content h1  {
    font-size: 3em;
  }
  
  .home-header-content h2{
  font-size: 2.5em;
}
.call-to-story {
  max-width: 200px;
  margin-top: 20px;
  padding: 10px 20px;
  border: 2px solid #dcd0e8;
  color:  #dcd0e8;
  text-decoration: none;
  font-weight: 600;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 1.2em;
  }
section h3 {
  font-size: 1.8em;
}

.home-header .home-header-background {
  height: 50vh;
}
.home-header-content {
  width: 90%; /* Réduit la largeur du contenu pour les petits écrans */
  text-align: center;
}

.home-container {
  padding: 1px; /* Réduit le padding pour les petits écrans */
}

.home-intro {
  flex-direction: column;
  text-align: center;
  height: 90vh;
}

.home-intro img {
  max-width: 200px;
  max-height: 200px;
  margin-top: 20px;
}

.citation-abdel {
  width: 80vw;
  text-align: center; /* Centrage du texte en mode mobile */
  
}

.experience-expertise {
  height: 100vh;
  padding-top: 10px;
  padding-bottom: 20px;
}
.experience-content {
  flex-direction: column;
}

.experience-content p {
  text-align: center;
  font-size: 1.2em;
}

.experience-content .expert-image {
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 20px;
}

.salon-content {
  flex-direction: column;
  width: 100%;
}

.salon-content p {
  text-align: center;
  width: 100%;
  font-size: 1.2em;
}

.salon-content .salon-image {
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 20px;
  object-fit: cover;
}

.salon-pour-tous p {
  width: 100vw;
  padding: 0;
}
.carousel{
  width: 300px;
  height: 100%;

}
.carousel .instagram-photo img {
  gap: 20px;
  height: 300px;
  width: 300px;
}


}

.fade-in-scroll {
opacity: 0;
transform: translateY(20px);
transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-scroll.visible {
opacity: 1;
transform: translateY(0);
}
