.contacthoraires-container {
  padding: 40px;
  text-align: center;
  font-family: 'General Sans', sans-serif;
  background: #f9f9f9;
}

.contacthoraires-header {
  background-color: #f9f4f9;
  padding: 40px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contacthoraires-header h1 {
  font-size: 3.5em;
  font-family: 'Enriqueta', serif;
  margin: 0;
  color: #651249;
  letter-spacing: 1px;
}

.contacthoraires-header p {
  font-size: 1.3em;
  color: #8b1f5a;
  margin-top: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.contacthoraires-content {
  font-size: 1.2em;
  color: #333;
}

.contact-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.cta-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
}

.cta-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #651249;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
  font-size: 1.1em;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  text-align: center;
}

.cta-button:hover {
  background-color: white;
  color: #651249;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.contact-details {
  flex: 2;
  text-align: left;
  background-color: #f9f4f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.contact-details p, .contact-details ul {
  font-size: 1.1em;
  margin: 10px 0;
}

.contact-details ul {
  list-style-type: none;
  padding: 0;
}

.contact-details ul li {
  margin: 5px 0;
}

a {
  text-decoration: none;
  color: #8b1f5a;
  transition: color 0.2s;
}

a:hover {
  color: #651249;
}

.map-container {
  margin-top: 50px;
  background-color: #f9f4f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.map-container h2 {
  font-size: 2.5em;
  margin-bottom: 15px;
  font-family: 'Enriqueta', serif;
  color: #651249;
  letter-spacing: 1px;
}

.sub-heading {
  font-size: 1.1em;
  color: #8b1f5a;
  margin-bottom: 20px;
  font-style: italic;
}
.google-info-container {
  margin-top: 40px;
  background-color: #f9f4f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.google-info-container h2 {
  font-size: 2em;
  color: #651249;
  margin-bottom: 10px;
  font-family: 'Enriqueta', serif;
  letter-spacing: 1px;
}

.google-info-container p {
  font-size: 1.1em;
  color: #8b1f5a;
  margin-top: 10px;
  line-height: 1.5;
}

.google-info-container a {
  color: #651249;
  text-decoration: underline;
  transition: color 0.2s;
}

.google-info-container a:hover {
  color: #8b1f5a;
}
iframe {
  border-radius: 10px;
  width: 100%;
  height: 350px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .contacthoraires-header h1 {
    font-size: 2.5em;
  }

  .contacthoraires-header p {
    font-size: 1em;
  }

  .contact-wrapper {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .cta-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .cta-button {
    width: 100%;
    max-width: 280px;
  }

  .contact-details {
    width: 100%;
    text-align: left;
    margin-top: 20px;
    max-width: 90%;
  }

  .contact-details p, .contact-details ul li {
    font-size: 1em;
  }

  .map-container {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
  }

  .map-container h2 {
    font-size: 1.8em;
  }

  .google-info-container h2 {
    font-size: 1.8em;
  }

  .google-info-container p {
    font-size: 1em;
  }
}
