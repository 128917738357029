.legal-container {
    font-family: 'General Sans', sans-serif;
    background-color: #f8f4fc; /* Assure une cohérence avec le thème du site */
    color: #451D47; /* Couleur principale pour le texte */
    max-width: 800px;
    margin: 0 auto; /* Centrer le contenu */
    padding: 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Légère ombre pour séparer la section du fond */
    border-radius: 8px;
  }
  
  .legal-container h2 {
    font-family: 'Shrikhand', cursive;
    color: #53195D; /* Utilisation d'une couleur de titre forte */
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .legal-container h3 {
    font-family: 'Enriqueta', serif;
    font-size: 1.8em;
    color: #651249;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .legal-container p {
    font-size: 1em;
    line-height: 1.6;
    color: #333;
    margin-bottom: 15px;
  }
  
  .legal-container a {
    color: #53195D;
    text-decoration: none;
    font-weight: bold;
  }
  
  .legal-container a:hover {
    text-decoration: underline;
  }
  
  .legal-container strong {
    color: #57155b;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .legal-container {
      padding: 20px;
    }
  
    .legal-container h2 {
      font-size: 2em;
    }
  
    .legal-container h3 {
      font-size: 1.5em;
    }
  
    .legal-container p {
      font-size: 0.95em;
    }
  }
  